import { axiosClient } from "../axios/axiosConfig";

export const scheduleApi = {
  create: async function (schedule) {
    const response = await axiosClient.request({
      url: `/schedules`,
      method: "POST",
      data: schedule,
    });

    return response.data;
  },
  getOnlySharedForUser: async function (username, search) {
    const response = await axiosClient.request({
      url: `/schedules/shared/username?username=${username}&search=${search}`,
      method: "GET",
    });

    return response.data;
  },
  getOnlyOwnedByUser: async function (username, search) {
    const response = await axiosClient.request({
      url: `/schedules/owned/username?username=${username}&search=${search}`,
      method: "GET",
    });

    return response.data;
  },
  getById: async function (id) {
    const response = await axiosClient.request({
      url: `/schedules/${id}`,
      method: "GET",
    });

    return response.data;
  },
  update: async function (schedule) {
    const response = await axiosClient.request({
      url: `/schedules/${schedule.id}`,
      method: "PUT",
      data: schedule,
    });

    return response.data;
  },
  delete: async function (id) {
    const response = await axiosClient.request({
      url: `/schedules/${id}`,
      method: "DELETE",
    });

    return response.data;
  },
};
